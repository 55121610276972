import { Navigate, Route, Routes, Outlet } from "react-router-dom";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import { SidebarMenuItem } from "../../../_metronic/layout/components/sidebar/sidebar-menu/SidebarMenuItem";
import { SidebarMenuItemWithSub } from "../../../_metronic/layout/components/sidebar/sidebar-menu/SidebarMenuItemWithSub";
import { Suspense, lazy } from "react";
import { SimpleLoader } from "../../general-components/loaders/simple-loader";

const chatBreadCrumbs: Array<PageLink> = [
  {
    title: "CRM",
    path: "/crm/contacts",
    isSeparator: false,
    isActive: false,
  },
  {
    title: "",
    path: "",
    isSeparator: true,
    isActive: false,
  },
];

const ContactPage = () => {
  const Contacts = lazy(() => import("./components/Contacts"));
  const Assets = lazy(() => import("./components/assets/assets"));
  const Organization = lazy(() => import("./components/Organization"));
  const Statement = lazy(() => import("./components/Statement"));
  const SingleContact = lazy(() => import("./components/SingleContact"));
  const SingleOrganization = lazy(
    () => import("./components/SingleOrganization")
  );
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="contacts"
          element={
            <>
              <PageTitle breadcrumbs={chatBreadCrumbs}>People</PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <Contacts />
              </Suspense>
            </>
          }
        />
        <Route
          path="contacts/:id"
          element={
            <>
              <PageTitle breadcrumbs={chatBreadCrumbs}>People</PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <SingleContact />
              </Suspense>
            </>
          }
        />
        <Route
          path="organizations"
          element={
            <Suspense fallback={<SimpleLoader />}>
              <Organization />
            </Suspense>
          }
        />
        <Route
          path="organizations/:id"
          element={
            <Suspense fallback={<SimpleLoader />}>
              <SingleOrganization />
            </Suspense>
          }
        />
        <Route
          path="statement"
          element={
            <Suspense fallback={<SimpleLoader />}>
              <Statement />
            </Suspense>
          }
        />
        <Route
          path="assets"
          element={
            <Suspense fallback={<SimpleLoader />}>
              <Assets />
            </Suspense>
          }
        />

        <Route index element={<Navigate to="/crm/contacts" />} />
      </Route>
    </Routes>
  );
};

export default ContactPage;

//============== MODULES ROUTES ==============

export const ContactsRoutes = () => (
  <SidebarMenuItemWithSub
    to="/crm"
    title="CRM"
    icon="abstract-34"
    fontIcon="bi-person"
  >
    <SidebarMenuItem
      to="/crm/organizations"
      title="Organizations"
      icon="bank"
      // fontIcon="bi-person"
    />
    <SidebarMenuItem
      to="/crm/contacts"
      title="Contacts"
      icon="profile-user"
      // fontIcon="bi-person"
    />
    <SidebarMenuItem
      to="/crm/assets"
      title="Assets"
      icon="truck"
      // fontIcon="bi-person"
    />
    <SidebarMenuItem
      to="/crm/statement"
      title="Statement"
      icon="document"
      // fontIcon="bi-person"
    />
  </SidebarMenuItemWithSub>
);
