import {
  httpDelete,
  httpGet,
  httpPost,
  httpPut,
} from "../modules/Auth/core/_requests";

export class OrganizationApi {
  private organization_url = "crm/organizations";

  createOrganization = async (data: any) => {
    let results = await httpPost(this.organization_url, data);
    return this.extractOrganization(results);
  };

  //update Organization
  updateOrganization = async (id: number | string, data: any) => {
    let results = await httpPut(`${this.organization_url}/${id}`, data);
    return this.extractOrganization(results);
  };

  //Get all Organization
  pullAllOrganization = async (path: string) => {
    let results = await httpGet(this.organization_url + "?" + path);
    return this.extractOrganization(results);
  };

  //Get one Organization
  getOneOrganization = async (id: number | string) => {
    let results = await httpGet(`${this.organization_url}/${id}`);
    return this.justOrganizationData(this.extractOrganization(results));
  };

  //Get all Organization
  deleteOrganization = (id: number | string) => {
    return httpDelete(`${this.organization_url}/${id}`);
  };

  extractOrganization = (results: any) => {
    if (results.status && results.data) {
      return results.data.data;
    } else return false;
  };
  justOrganizationData = (results: any) => {
    if (results.organization) {
      return results.organization;
    } else return false;
  };
}
