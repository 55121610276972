import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../modules/Auth";

export const ProtectedRoutes = () => {
  const { currentUser } = useAuth();
  const location = useLocation();
  return currentUser ? (
    <Outlet />
  ) : (
    <Navigate to="/auth" replace state={{ from: location }} />
  );
};

export const UnProtectedRoutes = () => {
  const { currentUser } = useAuth();
  return !currentUser ? <Outlet /> : <Navigate to="/dashboard" />;
};
