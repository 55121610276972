import { FC } from "react";

export const FaintedTitle: FC<{ title: string; className?: string }> = ({
  title,
  className,
}) => {
  return <h1 className={`text-muted ${className || "mt-2"}`}>{title}</h1>;
};

export const SectionTitle: FC<{ title: string; className?: string }> = ({
  title,
  className,
}) => {
  return <h4 className={className}>{title}</h4>;
};
