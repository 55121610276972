import { FC } from "react";

type Props = {
  children?: JSX.Element | JSX.Element[];
};
export const AlignLeft: FC<Props> = ({ children }) => {
  return (
    <div className="d-flex justify-content-end px-1 mt-2"> {children}</div>
  );
};

export const AlignRight: FC<Props> = ({ children }) => {
  return (
    <div className="d-flex justify-content-start px-1 mt-2 align-items-center">
      {" "}
      {children}
    </div>
  );
};
