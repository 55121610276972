import { resourceLimits } from "worker_threads";
import {
  httpDelete,
  httpGet,
  httpPost,
  httpPut,
} from "../modules/Auth/core/_requests";

export class ProvidersApi {
  private url: string = "settings/providers";
  createProviders = (data: any) => {
    return httpPost(this.url, data);
  };

  //update Providers
  updateProviders = (id: number | string, data: any) => {
    return httpPut(`${this.url}/${id}`, data);
  };

  //Get all Providers
  pullAllProviders = (params?: string) => {
    return httpGet(`${this.url}${params ? "?" + params : ""}`);
  };

  //Get one Providers
  getOneProviders = (id: number | string) => {
    return httpGet(`${this.url}/${id}`);
  };

  //Get all Providers
  deleteProviders = (id: number | string) => {
    return httpDelete(`${this.url}/${id}`);
  };

  extractProviders = (results: any) => {
    if (results.data && results.data.status) {
      return results.data.data;
    } else {
      return false;
    }
  };

  errorMessage = (results: any) => {
    if (results.data && !results.data.status) {
      return results.data.message;
    } else {
      return false;
    }
  };
}
