import { format } from "date-fns";

export const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
//edit date from everywhere and making it standard
export const mis_date_from_string = (date: any) =>
  format(new Date(date as string), "dd MMM yyyy");

export const mis_date_from_string_using_timezone = (date: any) => {
  if (!date) return "";
  return format(new Date(dateFromUTC(date)), "dd MMM yyyy");
};

export const mis_date_from_string_using_timezone_with_time = (date: any) => {
  if (!date) return "";

  return format(new Date(dateFromUTC(date)), "d MMM yyyy HH : mm 'Hrs'", {
    useAdditionalDayOfYearTokens: true,
  });
};

export const mis_date_put_day_first = (date: any) => {
  if (!date) return "";

  const datePart = date.split("T")[0];
  // Format the date using date-fns
  const formattedDate = format(new Date(datePart), "dd/MM/yyyy");
  return formattedDate;
};

//Coordinating timezones
//=============== CONVERT DATE TO UTC ==========
export function dateToUTC(date: any) {
  let dateObject = new Date(date);
  const formatter = new Intl.DateTimeFormat("en-US", {
    timeZone,
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZoneName: "short",
  });

  return new Date(formatter.format(dateObject));
}

//=============== UTC TO TIMEZONE ==============
export function dateFromUTC(utcDate: any) {
  let createDateObject = new Date(utcDate);
  const formatter = new Intl.DateTimeFormat("en-US", {
    timeZone,
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZoneName: "short",
  });

  const localDateString = formatter.format(createDateObject);
  return new Date(localDateString);
}

// =============== get today's date ==========
export const getTodayDate = (): string => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

//================= datetime picker to date time formart =================

export const dateTimeFormatToDateTime = (input: any) => {
  if (!input.length) return "";
  // Parse the input date-time string
  const date = new Date(input);

  // Extract individual date and time components
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hour = String(date.getHours()).padStart(2, "0");
  const minute = String(date.getMinutes()).padStart(2, "0");
  const second = String(date.getSeconds()).padStart(2, "0");

  // Format the date into the desired format
  return `${year}-${month}-${day} ${hour}:${minute}:${second}`;

  /**------------------ how to use this function ---------------
   * const input = '2024-08-23T05:08';
     const output = dateTimeFormatToDateTime(input);

      console.log(output); // Output: "23/08/2024 05:08:00"
   * 
   */
};

// =========================== DIFFERENCE BETWEEN TWO DATE IN HOURS,MINS AND DAYS ==========================

export const dateDifference = (
  start_date: string, //'2024-08-22 14:31:00'
  end_date: string //'2024-08-22 16:03:00'
) => {
  if (!start_date || !end_date)
    return {
      days: 0,
      hours: 0,
      minutes: 0,
    };
  const scheduleStartTime = new Date(start_date);
  const scheduleEndTime = new Date(end_date);

  // Calculate the difference in milliseconds
  const differenceInMs =
    scheduleEndTime.getTime() - scheduleStartTime.getTime();

  // Calculate the difference in days, hours, and minutes
  const differenceInDays = Math.floor(differenceInMs / (1000 * 60 * 60 * 24));
  const differenceInHours = Math.floor(
    (differenceInMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const differenceInMinutes = Math.floor(
    (differenceInMs % (1000 * 60 * 60)) / (1000 * 60)
  );

  let timeDifference = "";

  if (differenceInDays > 0) {
    timeDifference += `${differenceInDays} days`;
  }
  if (differenceInHours > 0) {
    timeDifference += `${timeDifference ? ", " : ""}${differenceInHours} ${
      differenceInHours === 1 ? "hour" : "hours"
    }`;
  }
  if (differenceInMinutes > 0) {
    timeDifference += `${
      timeDifference ? ", " : ""
    }${differenceInMinutes} minutes`;
  }

  // If all values are zero, you can handle it accordingly
  if (!timeDifference) {
    timeDifference = "0 minutes"; // Or any default message you prefer
  }
  return {
    days: differenceInDays,
    hours: differenceInHours,
    minutes: differenceInMinutes,
    inWords: timeDifference,
  };
};
