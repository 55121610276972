import { FC, ReactNode } from "react";

interface WrapperProps {
  children: ReactNode;
}
export const MISTable: FC<WrapperProps> = ({ children }) => {
  return (
    <div className="table-responsive">
      <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
        {children}
      </table>
    </div>
  );
};
